#artools-menu-popup .items{
  display:none;
  position: absolute !important;
  text-align: left !important;
  white-space: nowrap !important;
  z-index: 40;
  margin: -2px 0 0 0;
  background-color: white;
  box-shadow: 0 0 5px black(0.2);
  border: 1px solid black(0.2);
  border-top: 3px solid $gm-input-focus;
  border-radius: 2px;
  li {
    cursor: pointer;
    margin: 0;
    background-image: none;
    padding: 2px 0;
    a {
      font-size: 14px;
      line-height: 18px;
      padding: 0 10px;
      color: #363434 !important;
      display: block !important;
      text-decoration: none !important;
      white-space: nowrap;

    }
    &.has-children {
      padding-right:20px !important;
      @include font-icon('\f054');
      &:before {
        float: right;
        margin: 0 -17px 0 0;
      }
    }
    &:hover {
      background-color:#EDE1B5;
    }
    &>ul { display : none; }
    &.caption {
      background:none;
      background-color: black(0.8);
      border-radius: 0 0 2px 2px;
      height:17px;
      line-height:15px;
      margin: 3px -1px -5px;
      color:white;
      position:relative;
      font-size:14px !important;
      color:white;
      em { color:#DE18A7 !important; }
      a,
      a:hover{ color:white !important; }
      &:hover { background-color:#363636; color:white; border-color:#363636 !important; }
    }
  }

}

.ui-window {
  $bg : rgba(#2C2C2C, 0.8);
  background-color: $bg;
  box-shadow: 0 0 20px black;
  border-radius: 2;
  overflow:hidden;
  padding: 0;
  position: relative;
  .caption {
    color:black;
    background-color: white;
    height:30px;
    font-size:16px;
    line-height:30px;
    padding: 0 2px 2px 10px;
    position:relative;
    .close {
      position:absolute;
      right:5px;
      top:2px;
      @include font-icon('\f00d');
      cursor: pointer;
      display:inline-block;
      vertical-align:middle;
      display:table-cell;
      font-size:20px;
      padding:2px 2px 0 0;
      &:hover { color:#D41B5D }
    }
  }
  iframe {
    transition: margin-left ease 0.5s
  }
}

.ui-popup {
  @include ui-popup;
}
