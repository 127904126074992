@function black($opacity:0.8) {
  @return rgba(black, $opacity);
}

@function white($opacity:0.8) {
  @return rgba(white, $opacity);
}

@mixin _reset {
  width:auto;
  height:auto;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 none;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  -moz-font-feature-settings: inherit;
  -moz-font-language-override: inherit;
  vertical-align: baseline;
  line-height: 100%;
  background:none transparent;
  text-decoration: none;
  color:black;
  transition: none;
}

@mixin reset {
  @include _reset;
  * {
    @include _reset;
  }
}

@mixin height($h) {
  height:$h;
  line-height:$h;
  box-sizing: border-box;
}

@mixin clear {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

@mixin list-bullets {
  li {
    list-style-image: none;
    list-style-type: none;
    position:relative;
    margin-left: 1.4em;
    &:before {
      font-family:icons;
      font-size:15px;
      display:inline-block;
      position:absolute;
      left:-1.2em;
      @content;
    }
  }
}

@mixin list-numbered($suffix:".") {
  counter-reset: item 0;
  li {
    counter-increment: item;
    &:before {
      content: counter(item) $suffix;
      @content;
    }
  }
}


@mixin color($color) {
  color:$color;
  a { color: $color; }
}

@mixin list-inline {
  margin: 0;
  padding: 0;
  li {
    display:inline-block;
    padding: 0 1em 0 0;
  }
}

@mixin font-icon($code) {
  &:before {
    font-family: FontAwesome;
    content:$code;
    display: inline-block;
  }
}

@mixin ui-popup {
  display:none;
  position: absolute !important;
  text-align: left !important;
  white-space: nowrap !important;
  z-index: 40;
  margin: 0;
  padding: 0.25em 0 !important;
  background-color: white;
  box-shadow: 0 0 5px black(0.2);
  border: 1px solid #BFBFBF;
  border-radius: 2px;
  li {
    display: block !important;
    float: none !important;
    list-style: none !important;
    cursor: pointer;
    margin: 0;
    background-image: none;
    padding: 0 10px !important;
    border-top: 1px solid transparent;
    font-size: 14px;
    a {
      color: #363434 !important;
      display: block !important;
      line-height: 32px;
      text-decoration: none !important;
      white-space: nowrap;

    }
    &.has-children {
      padding-right:20px !important;
      &:before {
        content: "";
        float: right;
        margin: 0 -17px 0 0;
      }
    }
    &:hover {
      background-color:#D5D5D5;
    }
    &>ul { display : none; }
    &.caption {
      background:none;
      background-color: black(0.8);
      border-radius: 0 0 2px 2px;
      height:17px;
      line-height:15px;
      margin: 3px -1px -5px;
      color:white;
      position:relative;
      font-size:14px !important;
      color:white;
      em { color:#DE18A7 !important; }
      a,
      a:hover{ color:white !important; }
      &:hover { background-color:#363636; color:white; border-color:#363636 !important; }
    }
  }
}
