/*
 * This file is part of "artools".
 *    Copyright 2009-2011, arNuméral
 *    Author : Yoran Brault
 *    eMail  : yoran.brault@bad_arnumeral.fr (remove bad_ before sending an email)
 *    Site   : http://www.arnumeral.fr
 *
 * "artools" is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License as
 * published by the Free Software Foundation; either version 2.1 of
 * the License, or (at your option) any later version.
 *
 * "artools" is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
 * General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with "artools"; if not, write to the Free
 * Software Foundation, Inc., 51 Franklin St, Fifth Floor, Boston, MA
 * 02110-1301 USA, or see the FSF site: http://www.fsf.org.
 */

.artools-select {
  display: inline-block;
  padding: 0;
  position: relative;
  ul.selected {
    margin: 0 0 10px 0;
    font-size: 14px;
    li {
      @include artools-angle-left;
      &:before {
        color: #8EBBD0;
        padding: 0 2px 0 0;
      }
      line-height: 18px;
      span {
        &.delete {
          @include artools-minus;
          padding: 0 0 0 10px;
          color: gray;
          &:hover {
            color:#B44A16;
          }
          cursor: pointer;
        }
      }
      &:hover {
        background-color: #F7F7F7;
      }
    }
  }
  > .selector .items {
    display: none;
  }
  .ui-select {
    text-transform:none;
    padding: 0 0 0 5px;
    @include artools-triangle-down;
    @include gmSelect();
    &:before {
      text-align:center;
      font-size:20px;
      width: 50px;
      height: 23px; /* Pour prendre en compte la bordure parente */
      color: #747474;
      line-height:22px;
      display:block;
      float:right;
      margin:-2px 0 0 7px;
      border-radius: 0 4px 4px 0;
    }
  }

  &.simple > .selector {
    border: 1px solid #CDC1A5 !important;
    border-radius: 4px 4px 4px 4px !important;
    padding: 0 !important;
    position: absolute;
    a {
      background-position: center center;
      display: block;
      padding: 3px !important;
      text-indent: -10000px;
      width: 10px;
      height: 10px;
    }
  }
}

.multiSelect {
  > ul {
    width:    218px ! important;
    height:   150px;
    overflow: scroll;

  }
}
