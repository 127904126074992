// font family and version for artools library
  $artools-font-family: 'artools';
  $artools-version: '1.0';

// A mixin to define common icon settings
@mixin artools {
  &:before {
    font-family: $artools-font-family;
    display: inline-block;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
  }
}

// default icon class
.artools {
  @include artools;
  &:before {
    content: '\f100';
  }
  &.spin {
    &:before {
      animation-name: spin;
      animation-duration: 400ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}


// Defining the 'artools-add' glyph
$artools-add-code: '\f100'; // unicode for this icon
@mixin artools-add { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-add-code;
    @content;
  }
}
.artools-add { // defining the class of this icon
  @include artools-add;
}


// Defining the 'artools-angle-down' glyph
$artools-angle-down-code: '\f101'; // unicode for this icon
@mixin artools-angle-down { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-angle-down-code;
    @content;
  }
}
.artools-angle-down { // defining the class of this icon
  @include artools-angle-down;
}


// Defining the 'artools-angle-left' glyph
$artools-angle-left-code: '\f102'; // unicode for this icon
@mixin artools-angle-left { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-angle-left-code;
    @content;
  }
}
.artools-angle-left { // defining the class of this icon
  @include artools-angle-left;
}


// Defining the 'artools-angle-right' glyph
$artools-angle-right-code: '\f103'; // unicode for this icon
@mixin artools-angle-right { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-angle-right-code;
    @content;
  }
}
.artools-angle-right { // defining the class of this icon
  @include artools-angle-right;
}


// Defining the 'artools-angle-up' glyph
$artools-angle-up-code: '\f104'; // unicode for this icon
@mixin artools-angle-up { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-angle-up-code;
    @content;
  }
}
.artools-angle-up { // defining the class of this icon
  @include artools-angle-up;
}


// Defining the 'artools-chat' glyph
$artools-chat-code: '\f105'; // unicode for this icon
@mixin artools-chat { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-chat-code;
    @content;
  }
}
.artools-chat { // defining the class of this icon
  @include artools-chat;
}


// Defining the 'artools-check' glyph
$artools-check-code: '\f106'; // unicode for this icon
@mixin artools-check { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-check-code;
    @content;
  }
}
.artools-check { // defining the class of this icon
  @include artools-check;
}


// Defining the 'artools-close' glyph
$artools-close-code: '\f107'; // unicode for this icon
@mixin artools-close { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-close-code;
    @content;
  }
}
.artools-close { // defining the class of this icon
  @include artools-close;
}


// Defining the 'artools-code' glyph
$artools-code-code: '\f108'; // unicode for this icon
@mixin artools-code { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-code-code;
    @content;
  }
}
.artools-code { // defining the class of this icon
  @include artools-code;
}


// Defining the 'artools-content' glyph
$artools-content-code: '\f109'; // unicode for this icon
@mixin artools-content { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-content-code;
    @content;
  }
}
.artools-content { // defining the class of this icon
  @include artools-content;
}


// Defining the 'artools-developer-board' glyph
$artools-developer-board-code: '\f10a'; // unicode for this icon
@mixin artools-developer-board { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-developer-board-code;
    @content;
  }
}
.artools-developer-board { // defining the class of this icon
  @include artools-developer-board;
}


// Defining the 'artools-edit' glyph
$artools-edit-code: '\f10b'; // unicode for this icon
@mixin artools-edit { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-edit-code;
    @content;
  }
}
.artools-edit { // defining the class of this icon
  @include artools-edit;
}


// Defining the 'artools-error' glyph
$artools-error-code: '\f10c'; // unicode for this icon
@mixin artools-error { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-error-code;
    @content;
  }
}
.artools-error { // defining the class of this icon
  @include artools-error;
}


// Defining the 'artools-gears' glyph
$artools-gears-code: '\f10d'; // unicode for this icon
@mixin artools-gears { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-gears-code;
    @content;
  }
}
.artools-gears { // defining the class of this icon
  @include artools-gears;
}


// Defining the 'artools-home' glyph
$artools-home-code: '\f10e'; // unicode for this icon
@mixin artools-home { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-home-code;
    @content;
  }
}
.artools-home { // defining the class of this icon
  @include artools-home;
}


// Defining the 'artools-idea' glyph
$artools-idea-code: '\f10f'; // unicode for this icon
@mixin artools-idea { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-idea-code;
    @content;
  }
}
.artools-idea { // defining the class of this icon
  @include artools-idea;
}


// Defining the 'artools-info' glyph
$artools-info-code: '\f110'; // unicode for this icon
@mixin artools-info { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-info-code;
    @content;
  }
}
.artools-info { // defining the class of this icon
  @include artools-info;
}


// Defining the 'artools-mask' glyph
$artools-mask-code: '\f111'; // unicode for this icon
@mixin artools-mask { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-mask-code;
    @content;
  }
}
.artools-mask { // defining the class of this icon
  @include artools-mask;
}


// Defining the 'artools-menu' glyph
$artools-menu-code: '\f112'; // unicode for this icon
@mixin artools-menu { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-menu-code;
    @content;
  }
}
.artools-menu { // defining the class of this icon
  @include artools-menu;
}


// Defining the 'artools-menus' glyph
$artools-menus-code: '\f113'; // unicode for this icon
@mixin artools-menus { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-menus-code;
    @content;
  }
}
.artools-menus { // defining the class of this icon
  @include artools-menus;
}


// Defining the 'artools-minus' glyph
$artools-minus-code: '\f114'; // unicode for this icon
@mixin artools-minus { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-minus-code;
    @content;
  }
}
.artools-minus { // defining the class of this icon
  @include artools-minus;
}


// Defining the 'artools-more' glyph
$artools-more-code: '\f115'; // unicode for this icon
@mixin artools-more { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-more-code;
    @content;
  }
}
.artools-more { // defining the class of this icon
  @include artools-more;
}


// Defining the 'artools-ok' glyph
$artools-ok-code: '\f116'; // unicode for this icon
@mixin artools-ok { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-ok-code;
    @content;
  }
}
.artools-ok { // defining the class of this icon
  @include artools-ok;
}


// Defining the 'artools-person' glyph
$artools-person-code: '\f117'; // unicode for this icon
@mixin artools-person { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-person-code;
    @content;
  }
}
.artools-person { // defining the class of this icon
  @include artools-person;
}


// Defining the 'artools-persons' glyph
$artools-persons-code: '\f118'; // unicode for this icon
@mixin artools-persons { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-persons-code;
    @content;
  }
}
.artools-persons { // defining the class of this icon
  @include artools-persons;
}


// Defining the 'artools-plus' glyph
$artools-plus-code: '\f119'; // unicode for this icon
@mixin artools-plus { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-plus-code;
    @content;
  }
}
.artools-plus { // defining the class of this icon
  @include artools-plus;
}


// Defining the 'artools-reports' glyph
$artools-reports-code: '\f11a'; // unicode for this icon
@mixin artools-reports { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-reports-code;
    @content;
  }
}
.artools-reports { // defining the class of this icon
  @include artools-reports;
}


// Defining the 'artools-selected' glyph
$artools-selected-code: '\f11b'; // unicode for this icon
@mixin artools-selected { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-selected-code;
    @content;
  }
}
.artools-selected { // defining the class of this icon
  @include artools-selected;
}


// Defining the 'artools-settings' glyph
$artools-settings-code: '\f11c'; // unicode for this icon
@mixin artools-settings { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-settings-code;
    @content;
  }
}
.artools-settings { // defining the class of this icon
  @include artools-settings;
}


// Defining the 'artools-structure' glyph
$artools-structure-code: '\f11d'; // unicode for this icon
@mixin artools-structure { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-structure-code;
    @content;
  }
}
.artools-structure { // defining the class of this icon
  @include artools-structure;
}


// Defining the 'artools-tags' glyph
$artools-tags-code: '\f11e'; // unicode for this icon
@mixin artools-tags { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-tags-code;
    @content;
  }
}
.artools-tags { // defining the class of this icon
  @include artools-tags;
}


// Defining the 'artools-trash' glyph
$artools-trash-code: '\f11f'; // unicode for this icon
@mixin artools-trash { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-trash-code;
    @content;
  }
}
.artools-trash { // defining the class of this icon
  @include artools-trash;
}


// Defining the 'artools-triangle-down' glyph
$artools-triangle-down-code: '\f120'; // unicode for this icon
@mixin artools-triangle-down { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-triangle-down-code;
    @content;
  }
}
.artools-triangle-down { // defining the class of this icon
  @include artools-triangle-down;
}


// Defining the 'artools-triangle-left' glyph
$artools-triangle-left-code: '\f121'; // unicode for this icon
@mixin artools-triangle-left { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-triangle-left-code;
    @content;
  }
}
.artools-triangle-left { // defining the class of this icon
  @include artools-triangle-left;
}


// Defining the 'artools-triangle-right' glyph
$artools-triangle-right-code: '\f122'; // unicode for this icon
@mixin artools-triangle-right { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-triangle-right-code;
    @content;
  }
}
.artools-triangle-right { // defining the class of this icon
  @include artools-triangle-right;
}


// Defining the 'artools-triangle-up' glyph
$artools-triangle-up-code: '\f123'; // unicode for this icon
@mixin artools-triangle-up { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-triangle-up-code;
    @content;
  }
}
.artools-triangle-up { // defining the class of this icon
  @include artools-triangle-up;
}


// Defining the 'artools-unselected' glyph
$artools-unselected-code: '\f124'; // unicode for this icon
@mixin artools-unselected { // defining the mixin for this icon
  @include artools;
  &:before {
    content: $artools-unselected-code;
    @content;
  }
}
.artools-unselected { // defining the class of this icon
  @include artools-unselected;
}
